<template>
  <div class="addActivity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <!--        <v-button text="新增" @click="toAdd"></v-button>-->
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <community-select v-model="searchParam.communityId" label="选择园区" />
        <v-select
          clearable
          :options="auditStatusList"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
        <v-select
          clearable
          :options="cancelStatusList"
          v-model="searchParam.cancelStatus"
          @change="$refs.list.search()"
          label="取消状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          type="text"
          v-if="scope.row.auditStatus === 0"
          @click="toAudit(scope.row)"
        />
        <v-button
          text="确认"
          type="text"
          v-if="scope.row.auditStatus === 1 && !scope.row.confirmTime"
          @click="toConfirm(scope.row)"
        />
      </template>
    </v-list>

    <v-dialog
      title="审核"
      v-model="auditDialogFormVisible"
      width="50%"
      @confirm="doAudit"
    >
      <el-form label-width="100px">
        <el-form-item label="捐赠人">
          <v-input v-model="tempObj.donateUserName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="捐赠内容">
          <v-input v-model="tempObj.donateContent" :width="400" disabled />
        </el-form-item>
        <el-form-item label="捐赠图片">
          <v-upload :imgUrls.sync="tempObj.donatePic" disabled />
        </el-form-item>
        <el-form-item label="捐赠人地址">
          <v-input v-model="tempObj.housePath" :width="400" disabled />
        </el-form-item>
        <el-form-item label="审核结果">
          <v-select
            :options="auditStatusOptions"
            v-model="tempObj.auditStatus"
          />
        </el-form-item>
        <el-form-item label="奖励积分" v-if="tempObj.auditStatus === '1'">
          <v-input v-model="tempObj.bonusPoints" type="number" />
        </el-form-item>
        <el-form-item label="备注信息" v-if="tempObj.auditStatus === '1'">
          <v-input
            v-model="tempObj.auditRemarks"
            :width="400"
            :maxlength="100"
          />
        </el-form-item>
      </el-form>
    </v-dialog>
    <v-dialog
      title="确认捐赠"
      v-model="confirmDialogFormVisible"
      width="50%"
      @confirm="doConfirm"
    >
      <el-form label-width="100px">
        <el-form-item label="捐赠人">
          <v-input v-model="tempObj.donateUserName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="捐赠内容">
          <v-input v-model="tempObj.donateContent" :width="400" disabled />
        </el-form-item>
        <el-form-item label="捐赠图片">
          <v-upload :imgUrls.sync="tempObj.donatePic" disabled />
        </el-form-item>
        <el-form-item label="捐赠人地址">
          <v-input v-model="tempObj.housePath" :width="400" disabled />
        </el-form-item>
        <el-form-item label="审核人员">
          <v-input v-model="tempObj.auditUserName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="确认图片">
          <v-upload :imgUrls.sync="tempObj.confirmPic" />
        </el-form-item>
        <el-form-item label="备注信息">
          <v-input
            v-model="tempObj.confirmRemarks"
            :width="400"
            :maxlength="100"
          />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import { getDonateList, donateAudit, donateConfirm } from "./api.js";
import { createImgVNode } from "@/utils/utils.js";
import { auditStatus, cancelStatus } from "./map";

export default {
  name: "donateList",
  data() {
    return {
      auditStatusList: auditStatus,
      cancelStatusList: cancelStatus,
      searchParam: { communityId: null, auditStatus: null, cancelStatus: "0" },
      tableUrl: getDonateList,
      headers: [
        {
          prop: "communityName",
          label: "园区名称",
        },
        {
          prop: "donateUserName",
          label: "捐赠人姓名",
        },
        {
          prop: "donateUserPhone",
          label: "捐赠人手机号",
        },
        {
          prop: "housePath",
          label: "捐赠人房号",
        },
        {
          prop: "donateContent",
          label: "公益捐赠内容",
        },
        {
          prop: "donatePic",
          label: "公益捐赠图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },

        {
          prop: "insertTime",
          label: "捐赠申请时间",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < auditStatus.length; i++) {
              if (auditStatus[i].value == row.auditStatus) {
                return auditStatus[i].label;
              }
            }
          },
        },
        {
          prop: "auditTime",
          label: "审核时间",
        },
        {
          prop: "confirmTime",
          label: "确认时间",
        },
      ],

      auditDialogFormVisible: false,
      confirmDialogFormVisible: false,

      tempObj: {
        donateId: null,
        donateUserName: null,
        donateContent: null,
        donatePic: null,
        auditUserName: null,
        auditStatus: null,
        auditRemarks: null,
        confirmPic1: null,
        confirmPic: null,
        confirmRemarks: null,
        bonusPoints: 0,
      },
      auditStatusOptions: [
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    toAudit(data) {
      this.auditDialogFormVisible = true;
      this.tempObj = {
        donateId: data.donateId,
        donateUserName: data.donateUserName,
        donateContent: data.donateContent,
        donatePic: data.donatePic,
        housePath: data.housePath,
        bonusPoints: 0,
      };
    },
    doAudit() {
      if (!this.tempObj.auditStatus) {
        this.$message.error("请选择审核结果！");
        return;
      }
      if (this.tempObj.bonusPoints && this.tempObj.bonusPoints < 0) {
        this.$message.error("奖励积分不能小于0！");
        return;
      }
      this.$axios
        .post(`${donateAudit}`, null, {
          params: {
            donateId: this.tempObj.donateId,
            auditStatus: this.tempObj.auditStatus,
            auditRemarks: this.tempObj.auditRemarks,
            bonusPoints: this.tempObj.bonusPoints,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.auditDialogFormVisible = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    toConfirm(data) {
      this.confirmDialogFormVisible = true;
      this.tempObj = {
        donateId: data.donateId,
        donateUserName: data.donateUserName,
        donateContent: data.donateContent,
        donatePic: data.donatePic,
        housePath: data.housePath,
        auditUserName: data.auditUserName,
        confirmPic: "",
        confirmPic1: "",
      };
    },
    doConfirm() {
      this.$axios
        .post(`${donateConfirm}`, null, {
          params: {
            donateId: this.tempObj.donateId,
            confirmPic: this.tempObj.confirmPic,
            confirmRemarks: this.tempObj.confirmRemarks,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.confirmDialogFormVisible = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
}
</style>
